import { render, staticRenderFns } from "./HeaderCalendar.vue?vue&type=template&id=6b565967&scoped=true&"
import script from "./HeaderCalendar.vue?vue&type=script&lang=js&"
export * from "./HeaderCalendar.vue?vue&type=script&lang=js&"
import style0 from "./HeaderCalendar.vue?vue&type=style&index=0&id=6b565967&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b565967",
  null
  
)

export default component.exports