const TOKEN_KEY = "access_token";
const USER_KEY = "user";
const USER_EXPORT_KEY = "user_export";
const USER_EXPORT_ID_KEY = "user_id_export";
const REFRESH_TOKEN_KEY = "refresh_token";

const TokenService = {
	getToken() {
		return localStorage.getItem(TOKEN_KEY);
	},
	setToken(accessToken: string) {
		localStorage.setItem(TOKEN_KEY, accessToken);
	},
	getUser() {
		return localStorage.getItem(USER_KEY);
	},
	setExportUser(user: string) {
		localStorage.setItem(USER_EXPORT_KEY, user);
	},
	getExportUser() {
		return localStorage.getItem(USER_EXPORT_KEY);
	},
	setExportUserId(userId: string) {
		localStorage.setItem(USER_EXPORT_ID_KEY, userId);
	},
	getExportUserId() {
		return localStorage.getItem(USER_EXPORT_ID_KEY);
	},
	clearUser() {
		return localStorage.removeItem(USER_KEY);
	},
	setUser(userId: string) {
		localStorage.setItem(USER_KEY, userId);
	},
	removeToken() {
		localStorage.removeItem(TOKEN_KEY);
	},
	getRefreshToken() {
		return localStorage.getItem(REFRESH_TOKEN_KEY);
	},
	setRefreshToken(refreshToken: string) {
		localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
	},
	removeRefreshToken() {
		localStorage.removeItem(REFRESH_TOKEN_KEY);
	}
};

export { TokenService };
