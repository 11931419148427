<template>
  <aside class="aside">
    <nav>
      <ul>
        <li><a :href="`https://${link}/ru/main`">Главная</a></li>
        <li class="active"><a :href="`https://${link}/ru/educational-services`">Образовательные сервисы</a></li>
        <li><a :href="`https://${link}/ru/products`">Продукция</a></li>
        <li><a :href="`https://${link}/ru/company`">Компания</a></li>
        <li><a :href="`https://${link}/ru/users`">Сотрудники</a></li>
        <li><a :href="`https://${link}/ru/user-profile`">Профиль</a></li>
      </ul>
    </nav>
    <div class="aside__support">
      <div class="aside__support-label">Служба поддержки</div>
      <div class="aside__support-data">digital@exportcenter.ru</div>
    </div>
  </aside>
</template>

<script>
export default {
  name: "Sidebar",
  data() {
    return {
      link: process.env.VUE_APP_LK_API
    }
  }
}
</script>

<style scoped>

</style>