<template>
  <footer class="footer">
    <div class="footer__wrapper">
      <div class="footer__spoiler"></div>
      <div class="footer__content">
        <div class="footer__copyright">
          <div class="row align-items-center">
            <div class="col-auto footer__copyright-logo">
              <a href="#"><img src="/img/me-logo-footer.svg" /></a>
            </div>
            <div class="col d-none d-sm-block footer__copyright-info">
              <p>Портал разработан на базе цифровой платформы АО «Российский эспортный центр»
                в рамках национального проекта «Международная кооперация и экспорт»</p>
            </div>
            <div class="col-md-auto col-12 mt-2 mt-md-0 footer__copyright-text">© {{ new Date().getFullYear() }} Все права защищены.</div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterSeminars"
}
</script>

<style scoped>

</style>