<template>
  <div class="seminars-tpl">
    <header class="header">
      <div class="header__wrapper">
        <a class="header__logo" href="/">
          <img src="/img/me-logo.svg" alt="" title=""/>
        </a>

        <div class="header__user">
          <div class="dropdown" :class="dropSecondDown ? 'show' : ''">
            <button class="btn btn-secondary dropdown-toggle" @click="() => {this.dropSecondDown = !this.dropSecondDown}" type="button" id="dropdownMenuButton"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <span class="header__user-wrapper">User</span>
            </button>
            <div class="dropdown-menu" :class="dropSecondDown ? 'show' : ''" aria-labelledby="dropdownMenuButton">
              <a v-if="user" class="dropdown-item" :href="`https://${link}/ru/main`">
                {{user}}
              </a>
              <a v-else class="dropdown-item" :href="`https://${link}/ru/main`">...</a>
            </div>
          </div>
        </div>
      </div>

    </header>
  </div>
</template>

<script>
export default {
  name: "HeaderCalendar",
  props: ['user'],
  data() {
    return {
      dropFirstDown: false,
      dropSecondDown: false,
      link: process.env.VUE_APP_LK_API
    }
  },
  mounted() {

  }
}
</script>

<style lang="scss" scoped>
  .dotted-btn {
    border: none;
    background: inherit;
    font-weight: 400;
    &:focus {
      box-shadow: none!important;
    }
  }
  .dropdown-menu a {
    min-width: 218px;
    padding: 14px 16px;
    text-decoration: none;
    transition: all 0.3s ease 0s;
    color: black!important;
    &:hover {
      color: white!important;
      background-color: rgb(97, 119, 255);
    }
  }
  .dropdown-header-menu__dots {
    width: 30px;
    height: 30px;
    margin-left: 30px;
    cursor: pointer;
    svg {
      width: 30px;
      height: 30px;
    }
  }
</style>