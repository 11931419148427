import Vue from 'vue'
import App from './App.vue'
import { BootstrapVue } from 'bootstrap-vue'
import ApiService from "@/services/api.service";
import moment from "moment";
import VueCookies from 'vue-cookies'
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

Vue.config.productionTip = false
Vue.use(BootstrapVue)
    .use(VueCookies)

export const eventBus = new Vue()

ApiService.init(process.env.VUE_APP_ROOT_API);

Vue.filter('formatDate', (value: any, format: 'MM.DD.YYYY HH:mm') => {
  // @ts-ignore
  if('M' === format) {
    const number = moment(String(value)).format(format);
    switch (number) {
      case '1':
        return 'января'
      case '2':
        return 'февраля'
      case '3':
        return 'марта'
      case '4':
        return 'апреля'
      case '5':
        return 'мая'
      case '6':
        return 'июня'
      case '7':
        return 'июля'
      case '8':
        return 'августа'
      case '9':
        return 'сентября'
      case '10':
        return 'октября'
      case '11':
        return 'ноября'
      case '12':
        return 'декабря'
    }
  }

  if (value) {
    return moment(String(value)).format(format)
  }
})

import router from './router'

Sentry.init({
  Vue,
  dsn: "https://5b0674956dab450b8cf6ed344165212e@o255487.ingest.sentry.io/4504518973128704",
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["localhost", "export-school.exportcenter.ru", /^\//],
    }),
  ],
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
